import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import stapleIllustration from '../images/staple.svg';

const AboutPage = () => (
	<Layout>
		<SEO
			title="About"
			keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
		/>

		<div className="flex flex-col md:flex-row items-center">
			<div className="blog-post">

				<div className="flex mb-5">
					<div className="w-3/4 lg:w-5/6 flex items-center">
						<div>
							<h1 className="w-full">What is this all about?</h1>
						</div>
					</div>
					<div className="w-1/4 lg:w-1/6 flex items-center">
						<img src={stapleIllustration} alt="Staple" />
					</div>
				</div>

				<p className="font-bold mt-4">
					Staple is the online home of Gwilym Grffith-Jones, it is currently operating as a small concept to realisation ui/ux/concept/design/code outfit.
                    Lately most of my work has been in Laravel, Python and Wordpress/PHP. I also do a a bit of design and product development with the sewing machine
                    harking back to my days with <a href="https://www.cactusoutdoor.co.nz">Cactus Outdoor</a>.
				</p>
				<p>
					I built my first website with <a href="https://www.kennett.co.nz/about/paul">Paul Kennett</a> in 1992. Well he built it while I watched and marvelled.
					For some reason, ever since, I wanted to code.
				</p>
				<p>
					Since then through solving the real world web problems of businesses I've helped build, and launching
					pie-in-the-sky start-ups, I've metamorphosed into enough of a coder and designer to realise I really
					enjoy the creative challenges and freedoms the internet allows.
				</p>
				<p>
					From 2014 - 2019 I worked for a start up in Wanaka, <a href="https://www.kin2kin.com">kin2kin</a>.
					Principally designing user interface and running the Sketch App design system I also
					built the browser facing resources in Jekyll and React JS.
				</p>
				<p>
					In 2020, the Covid era, I have been working for some great supportive clients, and enjoying
                    family life.
				</p>

				<div className="text-xl font-bold mt-4 text-right text-xs uppercase font-sans">
					Gwilym Griffith-Jones<br />
					<a href="mailto:email@staple.co.nz">email@staple.co.nz</a><br />
                    +64 21 388 801<br />
					<a href="https://www.twitter.com/gwilymgj">@gwilymgj</a>
				</div>
			</div>

		</div>
	</Layout>
);

export default AboutPage
